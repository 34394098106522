import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import "../Styles/style.css";
import VerifiedIcon from "@mui/icons-material/Verified";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import  { BASE_URL } from "../components/config/config";
import AdminApiModule from "../components/AdminApi/Api/api";
import { Alert, InputAdornment, Snackbar, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Link } from "react-router-dom";
import CenterRegistrationApi from "../components/centerRegistration/Api/api";
import CenterComponentApi from "../components/centerComponent/api/api";
import { IndianStates } from "../assets/IndianStates";
import { withRouter } from "react-router";
import CopyButton from "../components/ServicesStatus/ClipbaordButton";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const stylesCss = {
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },
  gridStyle: {
    width: "80%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "10px",
  },
};

const AVAILABLE_TIME_PREFERENCES = [
  "IMMEDIATELY",
  "WITHIN_24_HOURS",
  "SPECIFIC_DATE_AND_TIME",
];

// Modal css
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid gray",
  boxShadow: 44,
  p: 4,
  borderRadius: 4,
};

//   List render
const listStyle = {
  width: "100%",
  // maxWidth: 360,
  bgcolor: "background.paper",
};

class AddTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceRequired: "",
      specificRequirement: "",
      name: "",
      phoneNumber: "",
      alternatePhoneNumber: "",
      houseNumber: "",
      locality: "",
      city: "",
      state: "",
      country: "INDIA",
      pincode: "",
      timePreference: "",
      authorizedForClient: "",
      offerCode: "OniT 2025",
      specific_date_time: new Date(),

      toast: {
        open: false,
        msg: "",
        duration: 5000,
        type: "",
      },
      isFormValid: false,
      servicesList: [],
      // code pasted here for auth
      doesTicketExists: false,
      isRegistering: false,
      TicketModalOpen: false,
      TicketModalData: [],
    };
  }

  componentDidMount() {
    this.getAllServicesExists();
     this.getAllClients();
  }

  getAllServicesExists() {
    new CenterRegistrationApi()
      .getAllActiveServices()
      .then((res) => {
        this.setState({
          servicesList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

//   Code pasted authorized clients
getAllClients() {
    new AdminApiModule()
      .getAllClients()
      .then((res) => {
        let parsedData = [];
        if (res && res.data && res.data.length > 0) {
          parsedData = res.data;
        }

        this.setState({
          clientsList: parsedData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getTicketDetails(editTicketId) {
    let payload = {
      ticket_obj_id: editTicketId,
    };
    this.setState({
      isEditDetailsExists: true,
    });

    new AdminApiModule()
      .getAllTickets(payload)
      .then((res) => {
        if (res?.data?.length) {
          this.setState({
            doesTicketExists: true,
          });

          this.updateTicketDetails(res?.data?.[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

    //   End

  updateTicketDetails(ticketDetails) {
    this.setState({
      serviceRequired: ticketDetails?.service_provided_for?._id,
      specificRequirement: ticketDetails?.specific_requirement,
      name: ticketDetails?.personal_details?.name,
      phoneNumber:
        ticketDetails?.personal_details?.primary_phone?.mobile_number,
      alternatePhoneNumber:
        ticketDetails?.personal_details?.alternate_phone?.mobile_number,
      houseNumber: ticketDetails?.address_details?.house_number,
      locality: ticketDetails?.address_details?.locality,
      city: ticketDetails?.address_details?.city,
      state: ticketDetails?.address_details?.state,
      country: "INDIA",
      pincode: ticketDetails?.address_details?.pincode,
      timePreference: ticketDetails?.time_preference?.time_preference_type,
      clients_ids_list: ticketDetails?.clients_ids_list,
      offerCode: "OniT 2025",
      is_paid: ticketDetails?.admin_setting?.is_paid,
      closing_ticket_price: ticketDetails?.closing_ticket_price,
      admin_remarks: ticketDetails?.remarks.admin_remarks,
      specific_date_time: ticketDetails?.time_preference?.specific_date_time,
    });
  }

  //   End

  showDialog = (open, type, msg) => {
    this.setState({
      toast: {
        open: open,
        msg: msg,
        duration: 5000,
        type: type,
      },
    });
  };

  closeDialog = (open = false) => {
    this.setState({
      toast: {
        open: open,
        msg: "",
        duration: 5000,
        type: "",
      },
    });
  };
  onChangeSpecificDateAndTime(newValue) {
    this.setState({ specific_date_time: newValue });
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    if (name == "pincode") {
      if (value.length <= 6) {
        this.setState({ [name]: value });
      }
      return;
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  handleRegister = () => {
    const {
      serviceRequired,
      specificRequirement,
      name,
      phoneNumber,
      alternatePhoneNumber,
      houseNumber,
      locality,
      city,
      state,
      country,
      pincode,
      timePreference,
      authorizedForClient,
      offerCode,
    } = this.state;

    if (
      !(
        serviceRequired &&
        name &&
        phoneNumber &&
        houseNumber &&
        pincode &&
        timePreference &&
        offerCode
      )
    ) {
      this.setState({
        isFormValid: true,
      });

      this.showDialog(true, "error", "Please fill in all required details");
      return;
    }

    this.setState({
      isRegistering: true,
    });

    if (this.state.timePreference == "SPECIFIC_DATE_AND_TIME") {
      if (!this.state.specific_date_time) {
        this.showDialog(true, "error", "Please fill in Specific date and time");
        return;
      }
    }

    let payload = 
    {
      personal_details: {
        primary_phone: {
          country_code: "+91",
          mobile_number: phoneNumber,
        },
        alternate_phone: {
          country_code: alternatePhoneNumber.length > 0 ? "+91" : "",
          mobile_number: alternatePhoneNumber,
        },
        name: name,
      },
      specific_requirement: specificRequirement,
      service_provided_for: serviceRequired,
      address_details: {
        house_number: houseNumber,
        locality: locality,
        city: city,
        state: state,
        pincode: pincode,
        country: country,
      },
      time_preference: {
        time_preference_type: timePreference,
        specific_date_time: this.state.specific_date_time,
      },
      offers_applied: {
        offer_code: offerCode,
      },
      // clients_ids_list: authorizedForClient,
    };

    // Code pasted for auth clients
    if (authorizedForClient) {
        payload["clients_ids_list"] = authorizedForClient;
      }
  
    
    

    new CenterComponentApi()
      .createTicket(payload)
      .then((res) => {
        // Code start
        if (!res || !res.data) {
          console.error("Error: No data received from API");
          return;
        }
        const TicketData = res?.data;
        console.warn("TicketData", TicketData);
        // Code end

        this.showDialog(true, "success", "Ticket Created successfully");

        this.setState({
          TicketModalData: TicketData,
          isRegistering: false,

          serviceRequired: "",
          specificRequirement: "",
          name: "",
          phoneNumber: "",
          alternatePhoneNumber: "",
          houseNumber: "",
          locality: "",
          city: "",
          state: "",
          country: "INDIA",
          pincode: "",
          timePreference: "",
          // code pasted
       
          offerCode: "",
        });

        setTimeout(() => {
          this.showDialog(true, "info", "Add more ticket");
          this.setState({
            TicketModalOpen: true,
          });
          // new code
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isRegistering: false,
        });
        this.showDialog(true, "error", err?.response?.data?.message);
      });
    // console.warn("TicketModalData",this.TicketModalData)
  };

  handleClose = () => {
    this.setState({
      TicketModalOpen: false,
    });
  };

  handleEditTechnician = (id) => {};

  // Clipboard code
  copyToClipboard = () => {
    const text = 'Text to be copied';

    /* Create a temporary input element */
    const tempInput = document.createElement('input');

    /* Set the value of the temporary input to the desired text */
    tempInput.setAttribute('value', text);

    /* Append the temporary input to the document */
    document.body.appendChild(tempInput);

    /* Select the text inside the temporary input */
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the temporary input to the clipboard */
    document.execCommand('copy');

    /* Remove the temporary input from the document */
    document.body.removeChild(tempInput);

    /* Alert the copied text */
    alert('Copied to clipboard: ' + text);
  };


  setToLocal =(id)=>{

    sessionStorage.setItem("ticketStatusId", id )
  }

  render() {
    // code pasted for for auth clients

    const {
      toast: snackBarValues,
      isFormValid,
      serviceRequired,
      specificRequirement,
      name,
      phoneNumber,
      alternatePhoneNumber,
      houseNumber,
      locality,
      city,
      state,
      country,
      pincode,
      timePreference,
      authorizedForClient,
      offerCode,
    } = this.state;

    const { TicketModalData } = this.state;
    console.log("TicketModalData in render", TicketModalData);

   
    return (
      <div>
        {/* Ticket creation modal code */}
      
        <Modal
          keepMounted
          open={this.state.TicketModalOpen}
          onClose={this.handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <VerifiedIcon color="success" fontSize="large" />
              Hurray! Your Request has been created
            </Typography>
            <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
              {/* List code  */}
              <List sx={listStyle} component="nav" aria-label="mailbox folders">
                <ListItem button>
                  <ListItemText primary="Ticket Id :-" />{" "}
                  <Typography> {TicketModalData?.ticket_id} </Typography>
                </ListItem>
                <Divider />
                <ListItem button divider>
                  <ListItemText primary="Ticket Status :-" />{" "}
                  <Typography> {TicketModalData?.ticket_status} </Typography>
                </ListItem>
                <ListItem button>
                  <ListItemText primary="Created At :-" />{" "}
                  <Typography> {TicketModalData?.createdAt} </Typography>
                </ListItem>
                <Divider light />
                <ListItem button>
                  {/* <ListItemText primary="Tracking Link :-" />{" "}
                  <Typography>
                    <Link to={`ServiceStatus/${TicketModalData?._id}`}>
                      Click me 
                    </Link>
                      <button onClick={this.copyToClipboard}>Copy</button>
                  </Typography> */}
                   <ListItemText primary="Tracking Link :-" />{" "}
                                    <Typography>
                                        <CopyButton webLink={`https://app.onit.services/#/ServiceStatus/?${TicketModalData?._id}`} />
                                        <Link
                                            style={{ marginLeft: '17px' }}
                                            to={`ServiceStatus/?${TicketModalData?._id}`}
                                            
                                        >
                                           <span onClick={this.setToLocal(TicketModalData?._id)}>Click me</span> 
                                        </Link>
                                    </Typography>
                </ListItem>
              </List>
            </Typography>
          </Box>
        </Modal>

        {snackBarValues.open && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBarValues.open}
            autoHideDuration={snackBarValues.duration}
            onClose={this.closeDialog}
          >
            <Alert
              onClose={this.closeDialog}
              severity={snackBarValues.type}
              sx={{ width: "100%" }}
            >
              {snackBarValues.msg}
            </Alert>
          </Snackbar>
        )}
        <h2 className="text-align-center">Add Ticket</h2>
        <Item>
          <Grid container>
            <Grid item md={6} style={stylesCss.paddingInnerGrids}>
              <FormControl
                md={6}
                style={stylesCss.inputBox}
                sx={{ minWidth: 120 }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  Service You Required
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Service You Required"
                  name="serviceRequired"
                  onChange={this.handleChange}
                  value={serviceRequired}
                  error={isFormValid && !serviceRequired}
                >
                  {this.state.servicesList?.map((ite) => {
                    return (
                      <MenuItem value={ite._id}>{ite.service_name}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Specific Requirement - it helps"
                style={stylesCss.inputBox}
                name="specificRequirement"
                value={specificRequirement}
                onChange={this.handleChange}
                error={isFormValid && !specificRequirement}
              />
            </Grid>

            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Name (Contact Person)"
                style={stylesCss.inputBox}
                name="name"
                value={name}
                onChange={this.handleChange}
                error={isFormValid && !name}
              />
            </Grid>
            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Phone Number"
                style={stylesCss.inputBox}
                name="phoneNumber"
                value={phoneNumber}
                onChange={this.handleChange}
                error={isFormValid && !phoneNumber}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>+91</Typography>
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>

            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Alternate Phone Number"
                style={stylesCss.inputBox}
                name="alternatePhoneNumber"
                value={alternatePhoneNumber}
                onChange={this.handleChange}
                // error={isFormValid && !alternatePhoneNumber}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>+91</Typography>
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="House Number / Street"
                style={stylesCss.inputBox}
                name="houseNumber"
                value={houseNumber}
                onChange={this.handleChange}
                error={isFormValid && !houseNumber}
              />
            </Grid>
            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Locality"
                style={stylesCss.inputBox}
                name="locality"
                value={locality}
                onChange={this.handleChange}
                error={isFormValid && !locality}
              />
            </Grid>
            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="City"
                style={stylesCss.inputBox}
                name="city"
                value={city}
                onChange={this.handleChange}
                error={isFormValid && !city}
              />
            </Grid>
            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <FormControl
                md={6}
                style={stylesCss.inputBox}
                sx={{ minWidth: 120 }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  State
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="State"
                  name="state"
                  onChange={this.handleChange}
                  value={state}
                  error={isFormValid && !state}
                >
                  {IndianStates?.map((ite) => {
                    return <MenuItem value={ite.value}>{ite.value}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Country"
                style={stylesCss.inputBox}
                name="country"
                value={country}
                onChange={this.handleChange}
                error={isFormValid && !country}
                disabled
              />
            </Grid>
            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Pincode"
                style={stylesCss.inputBox}
                name="pincode"
                type="number"
                value={pincode}
                onChange={this.handleChange}
                error={isFormValid && !pincode}
              />
            </Grid>
            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <FormControl
                md={6}
                style={stylesCss.inputBox}
                sx={{ minWidth: 120 }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  Time Preference
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Time Preference"
                  name="timePreference"
                  onChange={this.handleChange}
                  value={timePreference}
                  error={isFormValid && !timePreference}
                >
                  {AVAILABLE_TIME_PREFERENCES?.map((ite) => {
                    return <MenuItem value={ite}>{ite}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>

            {this.state.timePreference == "SPECIFIC_DATE_AND_TIME" ? (
              <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(params) => <TextField {...params} />}
                    label="Select date and time"
                    value={this.state.specific_date_time}
                    onChange={(newValue) => {
                      this.onChangeSpecificDateAndTime(newValue);
                    }}
                    minDate={new Date()}
                    minTime={new Date(0, 0, 0, 8)}
                    maxTime={new Date(0, 0, 0, 20)}
                  />
                </LocalizationProvider>
              </Grid>
            ) : (
              ""
            )}

            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Offer Code"
                style={stylesCss.inputBox}
                name="offerCode"
                value={offerCode}
                onChange={this.handleChange}
                error={isFormValid && !offerCode}
              />
            </Grid>

            {/* Code for authorized clients */}
            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <FormControl
                md={6}
                style={stylesCss.inputBox}
                sx={{ minWidth: 120 }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  Authorize for particular client
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Authorize for particular client"
                  name="authorizedForClient"
                  onChange={this.handleChange}
                  value={authorizedForClient}
                  // error={isFormValid && !authorizedForClient}
                >
                  {this.state.clientsList?.map((ite) => {
                    return (
                      <MenuItem value={ite._id}>{ite.client_name}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <Button
                variant="contained"
                onClick={() => this.handleRegister()}
                disabled={this.state.isRegistering}
              >
                SUBMIT
              </Button>
            </Grid>
          </Grid>
        </Item>
      </div>
    );
  }
}

export default AddTicket;
